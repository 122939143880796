import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";

export function Header(props) {
  return (
    <>
      <div id="header">
        <Container>
          <div id="header_brand">
            <h1 id="supguru_brand">SUP GURU</h1>
          </div>
          <div id="header_text">
            <ul>
              <li id="header-text-item">Купить сап в Череповце, магазин на ул.МонтКлер 20, 1й этаж</li>
              <li id="header-text-item">Прокат сапов в Череповце</li>
              <li id="header-text-item">Бесплатный тест-драйв перед покупкой</li>
              <li id="header-text-item">
                Доставка товара по городу и в регионы РФ
              </li>
            </ul>
            <ul>
              <li className="header_button">
                <a href="#hit_sell_container">
                  <Button variant="light">Хиты продаж</Button>
                </a>
              </li>
              <li className="header_button_1">
                <Button
                  variant="warning"
                  onClick={() => props.setShowHowCooseSupModal(true)}
                >
                  Как выбрать САП?
                </Button>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
}
