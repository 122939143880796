export function TelegramIcon(){

    
    
    return <>
   <div className="telegram_logo_box">
   <a href="https://t.me/+79115175912"><img alt="telegramLogo"  className="telegram_logo_image bounce-2" src="Telegram_2 1.png" /></a>
   </div>
        
    
    </>
}