import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';

export function HowChooseSupModal(props){
    return <Modal size="lg" show={props.showHowCooseSupModal} onHide={() => props.setShowHowCooseSupModal(false)}>
           <Modal.Header closeButton>
          <Modal.Title><h1>Как правильно выбрать SUP</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
            Чтобы правильно выбрать SUP мы разберёмся в нескольких простых
            параметрах.
            <h2>Форма:</h2>- Длинная и узкая доска имеет высокую скорость
            движения, но низкую устойчивость и манёвренность. Короткая и широкая
            доска имеет хорошую устойчивость и манёвренность, но низкую скорость
            движения. Толщина доски имеет прямое отношение к грузоподъёмности.
            SUP-доски по своей форме делятся на 4 основных вида:
            <h4>Прогулочные</h4>
            - Отличительной чертой прогулочных сапов является скруглённая форма
            носа и небольшая длина доски, за счёт этого они очень устойчивы,
            манёвренны и просты в управлении, на них легко кататься с любым
            уровнем подготовки. Отличный вариант для любителей комфорта.
            <h4>Туринговые</h4>
            - Форма таких досок имеет большую длину, широкий корпус и острый нос,
            это делает их удобными для продолжительных путешествий на большие
            расстояния. Очень устойчивые и грузоподъёмные, можно взять с собой в
            тур крупный багаж или пассажира - места хватит всем!
            <h4>Спортивные</h4>
            - Узкая длинная форма таких сапов позволяет набирать высокую скорость,
            они легко и быстро преодолевают большие расстояния. Чтобы уверенно
            стоять на такой доске, нужен небольшой опыт катания, но если он
            есть, то на обычной доске догнать вас будет очень трудно. С такой
            моделью за сезон вы наберёте отличную физическую форму!
            <h4>Универсальные</h4>
            - Эти модели сочетают в себе лучшие качества. Они достаточно
            устойчивы, в меру быстрые и манёвренные, грузоподъёмность зависит от
            размера доски. Если вам нужен сап чтобы просто кататься в своё
            удовольствие - это ваш выбор.
            <h2>Технология изготовления и качество:</h2>
            - Технология изготовления может быть однослойной или состоять из двух
            и более слоёв армированного ПВХ. Чем больше слоёв, тем надёжнее,
            прочнее и жёстче доска. При покупке обращайте внимание на качество
            склейки швов доски. Чем аккуратнее они выполнены, тем лучше. У всех
            без исключения брендов бывают гарантийные случаи, поэтому старайтесь
            покупать SUP с гарантией продавца. Будте аккуратны и не нарушайте
            правила эксплуатации. Вёсла тоже бывают разные. Самые простые и
            недорогие - алюминиевые, они рассматриваются как весла для
            начинающих. Фибергласс и карбон легче, жёстче и соответственно
            дороже чем алюминий, но именно они дают большую производительность в
            воде. Существует мнение, что фибергласс и карбон более ломкие. В
            теории всё так, но на практике вёсла из алюминия при очень сильных
            нагрузках начинают изнашиваться, в местах соединения секций
            появляется люфт. Предпочтительно иметь весло из фибергласса или
            карбона, главное не швырять его на землю и избегать контакта с
            камнями.
            <h2>Итог:</h2>- Прежде чем приобрести SUP определитесь со стилем
            катания. Новичкам и тем кто любит кататься с комфортом лучше всего
            подойдут прогулочные доски. Туринговые тоже достаточно комфортны и
            рассчитаны на длительные заезды с багажом или пассажиром. Для тех
            кто любит скорость подойдут спортивные модели. Выбирайте то, что Вам
            по душе. - Принимайте во внимание те места, где планируете кататься
            и какой стиль будет там наиболее актуален. Например, если это узкая
            извилистая речка или небольшое озеро, Вам подойдёт прогулочная
            модель. Если это широкая река с протяжёнными маршрутами, Вам
            подойдёт туринговая модель, а если при этом вы хотите ещё и
            погонять, то спортивная модель доски вас очень порадует - Обращайте
            внимание на качество изготовления. Склейка швов должна быть
            выполнена максимально аккуратно. Как правило, чем выше ценовая
            категория производителя, тем выше качество и надёжность. 
            <p>В нашем магазине Вы можете приобрести именно те доски, которые проверены нами 
                на качество и испытаны в реальных условиях!
            </p>
          
          </Container>
        </Modal.Body>
        <Modal.Header closeButton/>
    </Modal>
         
   
}