import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";

const styles = {
  container_about: {
    margin: "auto",
    paddingTop: "10px",
  },
};

export function About() {
  return (
    <Row style={styles.container_about} xs={1} md={2}>
      <Col className="display-flex-left">
        <div className="about_left">
          <div className="about_left_h1">
            <h1 className="about_h1">САП магазин</h1>
          </div>

          <p>
            Мы предлагаем недорого купить надувной сап борд в наличии в Череповце. 
            Вы легко можете приобрести sup доску со скидкой в нашем офлайн или
            интернет магазине, оформив понравившуюся модель за пару кликов! <b>Sup
            Guru</b> является официальным представителем компании Gladiator в
            России. Мы занимаемся продажей и прокатом сап-бордов с 2016 года.
            Имеем большой опыт в этой сфере и знаем как правильно выбрать сап. У
            нас вы можете получить <b>профессиональную консультацию</b>  по подбору
            доски и купить фирменный сап с дилерской гарантией по доступной
            цене.
            <br /> <br /> Адрес магазина: <b>г.Череповец, ул.Монтклер 20, 1й этаж</b>
            Возможна
            доставка товара на дом, самовывоз со склада в Череповце, или отправка 
            товара в любой город РФ транспортой компанией. С нами
            просто, удобно и выгодно!
          </p>
        </div>
      </Col>
      <Col id="rent_card" className="display-flex-right">
        <div className="about_left">
          <div className="about_left_h1">
            <h1 className="about_h1">САП ПРОКАТ</h1>
          </div>

          <p>
            Если Вы хотите покататься на сапах или протестировать интересующую
            Вас модель доски, то приезжайте к нам на прокат. Мы находимся на
            Чёрной Речке. <b>Бесплатные плюшки от Sup Guru:</b>
            <br />
            <ul>
              <li>тест-драйв (10 минут) при покупке доски </li>
              <li>вводный инструктаж и обучение</li>
              <li>
                по желанию выдаём спасательные жилеты и гермопакеты для личных
                вещей
              </li>
            </ul>
            <br />
            <br />
            <p>
              <b>Внимание!</b> Предварительная запись на прокат обязательна!{" "}
              <br />
              Телефон/WhatsApp/Telegram: +79115175912 или +79115062870
            </p>
          </p>
          <Button href="https://t.me/+79115175912" className="about_button">
            Записаться
          </Button>
          <Button id="how_ride" href="#map">
            Как проехать
          </Button>
        </div>
      </Col>
    </Row>
  );
}
