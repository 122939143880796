import { Container } from "react-bootstrap";

export function GladiatorHeader() {
  return (
    <div className="gladiator_header" id="gladiator_header">
        <div><img className="gladiator_header_img" src="gladiator-logo 1.png" alt="logo"></img></div>
        <Container>
      <div className="gladiator_header_text">
      <p className="gladiator_header_text_about">  
        Компания <b>Gladiator</b> занимается производством SUP-бордов с 2009 года. Тщательное
        внимание к деталям и стремление к созданию досок высокого качества
        привели к тому, что бренд Gladiator завоевал себе отличную репутацию и
        известен как производитель высококачественных досок премиум-класса
        по доступной цене. Компания уверена в качестве и надёжности своего
        продукта и предлагает 3-летнюю гарантию на SUP-борды. </p>
         
        
         
         
      </div>
      </Container>
    </div>
  );
}
